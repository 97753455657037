<div class="engine-wrapper">
  <app-engine
    (prizeEvent)="setPrize($event)"
    (wheelSpunEvent)="wheelSpun()"
    (wheelFinishedEvent)="wheelFinished($event)"
    (introAnimationFinishedEvent)="loadingFinished()"
  ></app-engine>
</div>

<div *ngIf="isWinner === true" class="popup-container">
  <div class="popup-circle">
    <div class="popup-content">
      <img src="./assets/images/viewa_logo.png" alt="" />
      <span class="lrg-text">CONGRATULATIONS</span>
      <span class="mid-text"
        >You've won a $3,000 credit towards a Viewa Campaign!</span
      >
      <div class="">
        <!-- <div class="banner-image">Redeem</div> -->
        <button class="pushable" (click)="gotoUnbounce()">
          <span class="front"> Redeem </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="popup-container clear move-up-and-down">
  <div [ngClass]="{ 'popup-message': true, hide: !showPopup }">
    <span class="mid-text block-font text-center text-white"
      ><b>Press To Spin</b></span
    >
  </div>
</div>

<div class="page-wrapper">
  <section class="form-header"></section>

  <div class="form-wrapper"></div>
</div>
