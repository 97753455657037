<div class="engine-wrapper">
  <canvas #rendererCanvas id="renderCanvas"></canvas>
</div>
<div class="loading-screen" #loadingScreen>
  <div class="image-container">
    <img
      class="loading-image"
      src="./assets/images/viewa_logo.png"
      draggable="false"
    />
  </div>
</div>
