import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { LocalStorageService } from '../services/local-storage.service'
import { EntryFormService } from '../services/entry-form.service';
import { UploadService } from '../services/upload.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ValidateSpend } from '../validators/spend.validator';
import { Upload } from '../models/Upload';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-entry-form',
  templateUrl: './entry-form.component.html',
  styleUrls: ['./entry-form.component.scss']
})
export class EntryFormComponent implements OnInit {

  @ViewChild("fileUpload") fileUpload: ElementRef;
  entryForm: FormGroup;
  filesToUpload: any[] = [];
  filesUploaded: Upload[] = [];

  constructor(private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private entryFormService: EntryFormService,
    private uploadService: UploadService,
    private router: Router) {



  }

  ngOnInit(): void {

    // Setup the form
    this.entryForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      spend: this.formBuilder.array([], [ValidateSpend]),
      optIn: [false, [Validators.requiredTrue]],
    })

    this.addSpend();
  }

  get spendForms() {
    return this.entryForm.get('spend') as FormArray;
  }

  addSpend(): void {
    const spendDetails = this.formBuilder.group({
      storeName: ['', [Validators.required]],
      totalSpend: ['', [Validators.required]]
    })

    this.spendForms.push(spendDetails);
  }

  deleteSpend(i): void {
    this.spendForms.removeAt(i);
  }

  submitHandler(): void {

    let formValue = this.entryForm.value;
    let upload = { upload: this.filesUploaded };

    let entry = { ...formValue, ...upload };

    this.entryFormService.submitEntryForm(entry)
      .subscribe(formResponse => {
        if (formResponse.isEligible) {
          this.localStorageService.saveSpinToken(formResponse.token);
          this.router.navigate(['/wheel'], { queryParams: { token: formResponse.token } });
          return;
        }
        this.router.navigate(['/closed']);
      });

  }

  uploadHandler() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
  }

  onFileUploadChange($event) {
    let files = $event.target.files;

    if (files.length > 0) {
      let toUpload: File = files[0];
      let idx = this.filesToUpload.push({ fileName: toUpload.name, uploaded: false });
      let formData = new FormData();

      formData.append('file', toUpload);

      this.uploadService.uploadFile(formData).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log(Math.round(100 * event.loaded / event.total));
        }
        else if (event.type === HttpEventType.Response) {

          // Update status
          let fileEntry = this.filesToUpload[idx - 1];
          fileEntry.uploaded = true;

          // Note uploaded file
          let r: Upload = event.body as Upload;
          this.filesUploaded.push(r);
        }
      });

    }

  }

  get disableFormForUploads(): boolean {

    if(this.filesUploaded.length==0) {
      return true;
    }

    const uploadCount = this.filesToUpload.length;
    let completeCount = this.filesToUpload.filter(f=>f.uploaded==true).length;

    if(uploadCount==completeCount) {
      return false;
    }

    return true;

  }

}
