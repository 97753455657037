import * as BABYLON from 'babylonjs';
import 'babylonjs-materials';
// import 'babylonjs-inspector';
import 'babylonjs-gui';
import 'babylonjs-loaders';
import { ElementRef, EventEmitter, Injectable, NgZone } from '@angular/core';
import { WheelService } from '../services/wheel.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SpinResponse } from '../models/SpinResponse';
import { PrizeInfo } from '../models/PrizeInfo';
import { PRIZE_TYPE } from '../models/PrizeType';

@Injectable({ providedIn: 'root' })
export class EngineService {
  private canvas: HTMLCanvasElement;
  private engine: BABYLON.Engine;
  private camera: BABYLON.ArcRotateCamera;
  private scene: BABYLON.Scene;
  private animationGroup: BABYLON.AnimationGroup;

  private rotationTime: number;
  private wheelSpinning: boolean = false;
  private previousFrame: number;
  private targetAngle: number;
  private animationSpeedMulti: number;
  private prizeEvent: EventEmitter<PrizeInfo>;
  private prizeInfo: PrizeInfo;

  private sound: BABYLON.Sound;

  private wheelSpunEvent: EventEmitter<void> = null;
  private animationStoppedEvent: EventEmitter<void> = null;
  private wheelFinishedEvent: EventEmitter<string> = null;
  private introAnimationFinishedEvent: EventEmitter<void> = null;

  private prizeWon: string = 'spin again';
  private firstAnimation: boolean = true;
  private hasSpun: boolean = false;

  private particleSystem: BABYLON.ParticleSystem;

  public constructor(
    private ngZone: NgZone,
    private wheelService: WheelService,
    private localStorageService: LocalStorageService
  ) {}

  public createScene(
    canvas: ElementRef<HTMLCanvasElement>,
    prizeEvent: EventEmitter<PrizeInfo>,
    loadingScreen: ElementRef,
    loadingFinishedEvent: EventEmitter<void>,
    wheelSpunEvent: EventEmitter<void>,
    wheelFinishedEvent: EventEmitter<string>,
    introAnimationFinishedEvent: EventEmitter<void>
  ): void {
    this.prizeEvent = prizeEvent;
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;

    this.wheelFinishedEvent = wheelFinishedEvent;
    this.introAnimationFinishedEvent = introAnimationFinishedEvent;

    // Then, load the Babylon 3D engine:
    this.engine = new BABYLON.Engine(this.canvas, true);

    // create a basic BJS Scene object
    this.scene = new BABYLON.Scene(this.engine);
    this.scene.autoClear = true;
    this.scene.autoClearDepthAndStencil = true;
    // if (window.location.host == 'localhost:4200') {
    //   this.scene.debugLayer.show();
    // }

    this.wheelSpunEvent = wheelSpunEvent;

    // custom loading screen used to make the loading screen fill the hole screen
    function customLoadingScreen() {
      // console.log("customLoadingScreen creation");
    }
    customLoadingScreen.prototype.displayLoadingUI = function () {
      // console.log("customLoadingScreen loading");
    };
    customLoadingScreen.prototype.hideLoadingUI = () => {
      // hides loading element once loading has finished
      loadingScreen?.nativeElement?.classList.add('hidden');
      this.engine.resize();
      loadingFinishedEvent.emit();
    };
    let cls = new customLoadingScreen();
    this.engine.loadingScreen = cls;

    // create a camera
    let radius = 2;
    let verticalCameraRange = 0.7;
    let horizontalCameraRange = 0.7;

    // This creates and positions a arc camera
    this.camera = new BABYLON.ArcRotateCamera(
      'camera',
      0,
      1.483,
      radius,
      new BABYLON.Vector3(0, 0.8, 0),
      this.scene
    );
    this.camera.panningSensibility = 0;
    this.camera.lowerRadiusLimit = radius;
    this.camera.upperRadiusLimit = radius;
    this.camera.lowerAlphaLimit = -horizontalCameraRange;
    this.camera.upperAlphaLimit = horizontalCameraRange;
    this.camera.lowerBetaLimit = 1.57 - verticalCameraRange;
    this.camera.upperBetaLimit = 1.57 + verticalCameraRange;
    // this.camera.layerMask = 1;

    // attach the camera to the canvas
    this.camera.attachControl(this.canvas, false);

    const _this = this;

    // load models
    BABYLON.SceneLoader.AppendAsync(
      './assets/',
      'rf-wheel4.glb',
      this.scene
    ).then(
      function (scene: BABYLON.Scene) {
        // makes background color clear
        // scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

        // pauses animation and sets animation group
        scene.animationGroups[0].pause();
        _this.animationGroup = scene.animationGroups[0];

        let vogueButton: BABYLON.AbstractMesh;
        let wheel: BABYLON.AbstractMesh;
        let pointerSupport: BABYLON.AbstractMesh;
        let glassMat;

        scene.meshes.forEach((mesh) => {
          if (mesh.name === 'Vogue Button') {
            vogueButton = mesh;
          }
          if (mesh.name === 'Wheel') {
            wheel = mesh;
          }
          if (mesh.name === 'Pointer Support') {
            pointerSupport = mesh;
          }
        });

        scene.materials.forEach((mat) => {
          if (mat.name === 'Glass') {
            glassMat = mat;
          }
        });

        //glassMat.forceIrradianceInFragment = true;

        // let ambientLight = new BABYLON.HemisphericLight(
        //   'HemiLight',
        //   new BABYLON.Vector3(0, 1, 0),
        //   scene
        // );

        // add button press functionality
        vogueButton.actionManager = new BABYLON.ActionManager(scene);

        // scales mesh when hovered
        vogueButton.actionManager.registerAction(
          new BABYLON.InterpolateValueAction(
            BABYLON.ActionManager.OnPointerOutTrigger,
            vogueButton,
            'scaling',
            new BABYLON.Vector3(1, 1, 1),
            150
          )
        );
        vogueButton.actionManager.registerAction(
          new BABYLON.InterpolateValueAction(
            BABYLON.ActionManager.OnPointerOverTrigger,
            vogueButton,
            'scaling',
            new BABYLON.Vector3(1.1, 1.1, 1.1),
            150
          )
        );

        // runs code when mesh is clicked
        vogueButton.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPickTrigger,
            () => {
              _this.spinWheel();
            }
          )
        );

        scene.registerBeforeRender(() => {
          if (_this.wheelSpinning) _this.spinUpdate();
        });

        // setup background / environment texture
        // how to convert hdr to env file https://doc.babylonjs.com/how_to/use_hdr_environment
        // let envTexture = new BABYLON.HDRCubeTexture(
        //   './assets/test2.hdr',
        //   scene,
        //   512
        // );

        // warning setting these values to high will crash/hang on some devices
        const skybox_resolution = '512';
        const env_resolution = '256';

        // Skybox
        let skybox = BABYLON.MeshBuilder.CreateBox(
          'skyBox',
          { size: 1000.0 },
          scene
        );

        let skyboxMaterial = new BABYLON.StandardMaterial('skyBox', scene);
        skyboxMaterial.backFaceCulling = false;
        skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture(
          'textures/skybox',
          scene,
          undefined,
          undefined,
          [
            `assets/skybox/0001-${skybox_resolution}.jpg`, // front
            `assets/skybox/0006-${skybox_resolution}.jpg`, // up
            `assets/skybox/0002-${skybox_resolution}.jpg`, // left
            `assets/skybox/0003-${256}.jpg`, // back, I have set it to the lowest res as no one can see the image
            `assets/skybox/0005-${skybox_resolution}.jpg`, // down
            `assets/skybox/0004-${skybox_resolution}.jpg`, // right
          ]
        );
        skyboxMaterial.reflectionTexture.coordinatesMode =
          BABYLON.Texture.SKYBOX_MODE;
        skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        skybox.material = skyboxMaterial;
        skybox.rotation = new BABYLON.Vector3(0, Math.PI, 0);

        //envTexture.rotationY = 163.0;
        // let envTexture = new BABYLON.HDRCubeTexture("./assets/cinema_lobby_4k.hdr", scene, 1024);
        // let envTexture = new BABYLON.CubeTexture("./assets/environment.env", scene);
        // let skyboxTexture = new BABYLON.CubeTexture('./assets/skybox/', scene, ['side_image.png', 'top_image.png', 'side_image.png', 'side_image.png',  'bottom_image.png', 'side_image.png']);

        scene.environmentTexture = new BABYLON.CubeTexture(
          'textures/skybox',
          scene,
          undefined,
          undefined,
          [
            `assets/skybox/0001-${env_resolution}.jpg`, // front
            `assets/skybox/0006-${env_resolution}.jpg`, // up
            `assets/skybox/0002-${env_resolution}.jpg`, // left
            `assets/skybox/0003-${256}.jpg`, // back, I have set it to the lowest res as no one can see the image
            `assets/skybox/0005-${env_resolution}.jpg`, // down
            `assets/skybox/0004-${env_resolution}.jpg`, // right
          ]
        );

        //makes skybox not visible to camera
        //skybox.layerMask = 2;

        // sets up glow for glow strips https://doc.babylonjs.com/how_to/glow_layer
        // let gl = new BABYLON.GlowLayer('glow', scene, {
        //   mainTextureFixedSize: 32,
        //   blurKernelSize: 8,
        // });
        // gl.intensity = 0.5;

        //sets up glass material

        // mat.disableLighting = true;
        // let rtt = new BABYLON.RenderTargetTexture(
        //   'Glass render Texture',
        //   128,
        //   scene
        // );
        // rtt.renderList = [];
        // First we blur
        // let blurV = new BABYLON.BlurPostProcess(
        //   'blurV',
        //   new BABYLON.Vector2(0, 1),
        //   64,
        //   0.5,
        //   undefined,
        //   undefined,
        //   _this.engine
        // );
        // let blurH = new BABYLON.BlurPostProcess(
        //   'blurH',
        //   new BABYLON.Vector2(1, 0),
        //   64,
        //   0.5,
        //   undefined,
        //   undefined,
        //   _this.engine
        // );
        // rtt.addPostProcess(blurH);
        // rtt.addPostProcess(blurV);
        // glassMat.refractionTexture = envTexture;
        // glassMat.refractionTexture.coordinatesMode = BABYLON.Texture.CUBIC_MODE;

        //Adds skybox mesh
        // rtt.renderList.push(skybox);
        // //adds
        // rtt.renderList.push(pointerSupport);

        //sets the texture to clamp
        scene.textures.forEach((texture: BABYLON.BaseTexture) => {
          if (texture.name === 'Text Image (Base Color)') {
            texture.wrapU = BABYLON.RawTexture.CLAMP_ADDRESSMODE;
            texture.wrapV = BABYLON.RawTexture.CLAMP_ADDRESSMODE;
          }
        });

        // _this.sound = new BABYLON.Sound(
        //   'Sound',
        //   './assets/Wheel Spin edited.mp3',
        //   scene
        // );

        // Emitter
        _this.particleSystem = new BABYLON.ParticleSystem(
          'particles',
          10000,
          scene
        );
        _this.particleSystem.particleTexture = new BABYLON.Texture(
          './assets/images/flare.png',
          scene
        );

        _this.particleSystem.minSize = 0.05;
        _this.particleSystem.maxSize = 0.1;

        // Where the particles come from
        const meshEmitter = new BABYLON.MeshParticleEmitter(wheel);
        _this.particleSystem.particleEmitterType = meshEmitter;

        _this.particleSystem.emitter = wheel;

        // Life time of each particle (random between...
        _this.particleSystem.minLifeTime = 4.0;
        _this.particleSystem.maxLifeTime = 4.0;

        // Emission rate
        _this.particleSystem.emitRate = 100;

        // Blend mode : BLENDMODE_ONEONE, or BLENDMODE_STANDARD
        _this.particleSystem.blendMode =
          BABYLON.ParticleSystem.BLENDMODE_ONEONE;

        // Set the gravity of all particles
        _this.particleSystem.gravity = new BABYLON.Vector3(0, 0, 0);

        // Speed
        _this.particleSystem.minEmitPower = 1;
        _this.particleSystem.maxEmitPower = 4;
        _this.particleSystem.updateSpeed = 1 / 240;

        //Start the particle system
        _this.particleSystem.start();

        // // Create the Volumetric lighting effect for each light
        // let godrays = new BABYLON.VolumetricLightScatteringPostProcess(
        //   'godrays',
        //   1.0,
        //   _this.camera,
        //   scene.getMeshByName('Lights_primitive1') as BABYLON.Mesh,
        //   200,
        //   BABYLON.Texture.BILINEAR_SAMPLINGMODE,
        //   _this.engine,
        //   false
        // );
        // let godrays2 = new BABYLON.VolumetricLightScatteringPostProcess(
        //   'godrays',
        //   1.0,
        //   _this.camera,
        //   scene.getMeshByName('Lights.001_primitive1') as BABYLON.Mesh,
        //   200,
        //   BABYLON.Texture.BILINEAR_SAMPLINGMODE,
        //   _this.engine,
        //   false
        // );

        // // good for testing settings
        // // window.godrays = godrays;
        // const exposure = 0.3;
        // const decay = 0.99;
        // const weight = 0.3;
        // const density = 1;
        // godrays.exposure = exposure;
        // godrays.decay = decay;
        // godrays.weight = weight;
        // godrays.density = density;
        // godrays2.exposure = exposure;
        // godrays2.decay = decay;
        // godrays2.weight = weight;
        // godrays2.density = density;

        // spins the wheel around once
        _this.spinWheelToAngle(360, 2);
        // plays camera animation
        _this.cameraAnimation();
      },
      (reason) => {
        console.error(reason);
      }
    );
  }

  public runRenderLoop(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      const rendererLoopCallback = () => {
        this.scene.render();
      };

      if (window.document.readyState !== 'loading') {
        this.engine.runRenderLoop(rendererLoopCallback);
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.engine.runRenderLoop(rendererLoopCallback);
        });
      }

      window.addEventListener('resize', () => {
        this.engine.resize();
      });
    });
  }

  private spinWheel() {
    //prevents user from repeatedly clicking button
    if (this.wheelSpinning === true) return;

    this.wheelSpunEvent.emit();

    let factor = Math.round(Math.random());

    if (this.hasSpun && this.prizeWon === 'spin again') {
      factor = 0;
    }

    if (factor === 0) {
      this.prizeWon = 'Win';
    }

    // 0 == win, 1 == spin again
    let angleToSpin =
      720 +
      Math.round(Math.random() * 4) * 90 +
      30 * factor +
      (20 + Math.round(Math.random() * 8));
    this.hasSpun = true;
    this.spinWheelToAngle(angleToSpin);
  }

  private spinWheelToAngle(
    angle: number,
    animationSpeedMulti: number = 1
  ): void {
    if (!this.wheelSpinning) {
      // resets rotation position and rotation time
      this.animationGroup.goToFrame(0);
      this.rotationTime = 0;
      this.targetAngle = angle;
      this.animationSpeedMulti = animationSpeedMulti;
      this.wheelSpinning = true;
    }
  }

  private spinUpdate() {
    // getAnimationRatio is used to compensate for a higher or lower framerate
    this.rotationTime =
      this.rotationTime +
      0.001 * this.scene.getAnimationRatio() * this.animationSpeedMulti;
    if (this.rotationTime > 1) {
      // finished animation
      this.wheelSpinning = false;
      this.rotationTime = 1;
      if (!this.firstAnimation) {
        console.log(`finished spinnng: ${this.prizeWon}`);
        this.wheelFinishedEvent.emit(this.prizeWon);
      } else {
        this.firstAnimation = false;
      }
    }

    // uses basic ease function
    let output = this.rotationTime * (2 - this.rotationTime); // from 0 - 1

    // the current animation goes from 0 to 15 frames
    let value = (((output * 15) / 360) * this.targetAngle) % 15;

    if (this.previousFrame) {
      //the total duration of the animation is 15 frames
      // there are 12 pins around the wheel so the pointer hitting a pin should occur every 15/12 frames or every 1.25 frames
      let collisionFrameInterval = 15 / 12;

      // as the collision occurs slighting beforehand in the animation (currently 15th of the collision interval)
      let collisionFrameOffset = collisionFrameInterval / 15;

      // gets the values for the next and previous frames, these values will go from 0 to 1.25, then repeat
      let pre =
        (this.previousFrame + collisionFrameOffset) % collisionFrameInterval;
      let next = (value + collisionFrameOffset) % collisionFrameInterval;

      // when the next value is lower than the next value the pointer will have hit a pin so the sound is played
      if (next < pre) {
        this.playSound();
      }
    }
    this.animationGroup.goToFrame(value);
    this.previousFrame = value;
  }

  private playSound() {
    // sound effect taken from http://soundbible.com/673-Game-Show-Wheel-Spin.html
    // SHOULD NOT USE FOR PRODUCTION AS IT REQUIRES ATTRIBUTION
    if (this.sound) {
      this.sound.play();
    }
  }

  private cameraAnimation() {
    const keyFrames = [];
    // animates teh alpha property of the camera
    const camRotate = new BABYLON.Animation(
      'camRotate',
      'alpha',
      30,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    keyFrames.push({
      frame: 0,
      value: -0.7,
    });

    keyFrames.push({
      frame: 150,
      value: 0.7,
    });

    keyFrames.push({
      frame: 240,
      value: 0,
    });

    camRotate.setKeys(keyFrames);

    //sets up easing function
    const easingFunction = new BABYLON.SineEase();
    easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    camRotate.setEasingFunction(easingFunction);

    //adds animation to camera
    this.camera.animations.push(camRotate);

    // plays camera rotation
    this.scene.beginAnimation(this.camera, 0, 240, false, 1, () => {
      this.particleSystem.stop();
      this.introAnimationFinishedEvent.emit();
    });
  }
}
