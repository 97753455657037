import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PrizeInfo } from '../models/PrizeInfo';
import { EngineService } from './engine.service';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.scss'],
})
export class EngineComponent implements OnInit {
  @Output() prizeEvent = new EventEmitter<PrizeInfo>();
  // TODO change loadingFinishedEvent to not require string
  @Output() loadingFinishedEvent = new EventEmitter<void>();
  @Output() wheelSpunEvent = new EventEmitter<void>();
  @Output() wheelFinishedEvent = new EventEmitter<string>();

  @Output() introAnimationFinishedEvent = new EventEmitter();

  @ViewChild('loadingScreen') loadingScreen: ElementRef;
  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  constructor(private engServ: EngineService) {}

  ngOnInit(): void {}

  public ngAfterViewInit() {
    // passes event emitter to engine service
    this.engServ.createScene(
      this.rendererCanvas,
      this.prizeEvent,
      this.loadingScreen,
      this.loadingFinishedEvent,
      this.wheelSpunEvent,
      this.wheelFinishedEvent,
      this.introAnimationFinishedEvent
    );
    this.engServ.runRenderLoop();
  }
}
