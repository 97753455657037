<div class="page-wrapper">
  <section class="form-header">
    <img src="assets/images/Header_Desktop.png"
    alt="Voge Header Images"
    sizes="(max-width:600px) 480px, (max-width: 800px) 800px, 1024px"
    srcset="assets/images/Header_Phone.png 480w,
            assets/images/Header_Tablet.png 800w,
            assets/images/Header_Desktop.png 1024w
    "
    class="header-image"
    >
  </section>

  <div class="form-wrapper">

      <section class="form-text">
          <h1>Enter your details for your chance to spin the Vogue American Express Fashion's Night In Prize wheel!</h1>
      </section>
      <section class="form-fields">
        <form [formGroup]="entryForm">

              <label for="firstName">First Name*</label>
              <input type="text" formControlName="firstName" id="firstName">

              <label for="lastName">Surname*</label>
              <input type="text" formControlName="lastName" id="lastName">

              <label for="email">Email Address*</label>
              <input type="email" formControlName="email" id="email">

              <label for="">Purchases* (Must total over $150)</label>
              <div class="spend-wrapper" formArrayName="spend">
                <div class="spend-lines" *ngFor="let row of spendForms.controls; let i = index" [formGroupName]="i">

                  <div class="spend-line-holder">
                    <label for="email">Store Name*</label>
                    <input type="text" formControlName="storeName">
                  </div>
                  <div class="spend-line-holder">
                    <label for="email">Amount ($)*</label>
                    <input type="text" formControlName="totalSpend">
                  </div>

                </div>
              </div>

              <button class="add-spend-button" (click)="addSpend()">Add</button>

              <label for="">Receipts*</label>


              <div class="receipts-wrapper">
                <div class="upload-display">
                  <ul class="">
                    <li *ngFor="let f of filesToUpload">
                      <span class="upload-tick" *ngIf="f.uploaded">✔</span><span>{{ f.fileName }}</span></li>
                  </ul>
                </div>
                <div class="upload-selector">
                  <button class="receipt-upload-button" (click)="uploadHandler()">Upload</button>
                  <input type="file" #fileUpload id="fileUpload" name="fileUpload" accept="image/*,application/pdf" style="display:none;" (change)="onFileUploadChange($event)" />
                </div>

              </div>

              <div class="checkbox-wrapper">
                  <label class="checkbox-label">
                      <input formControlName="optIn" id="optIn" type="checkbox">
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <span class="opt-in-label">By checking this box...*</span>
              </div>

              <div class="t-c-wrapper">
                <span>You agree to receive e-newsletters and marketing emails from Vogue.
                  You may unsubscribe from these emails at any stage in accordance with our Privacy Policy.
                  See our privacy policy at <a href="https://preferences.news.com.au/privacy">https://preferences.news.com.au/privacy</a></span>
              </div>


              <button (click)="submitHandler()" [disabled]="!entryForm.valid || disableFormForUploads">Spin to Win</button>
          </form>
      </section>
  </div>

  <footer>
      <img src="assets/images/Footer_Desktop.png"
      alt="Voge Header Images"
      class="footer-image"
      >
  </footer>
</div>

