import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EntryFormComponent } from './entry-form/entry-form.component';
import { ErrorsComponent } from './errors/errors.component';
import { WheelComponent } from './wheel/wheel.component';
import { TokenGuard } from './guards/token-guard.guard';


const routes: Routes = [
  // { path: 'spintowin', component: EntryFormComponent, canActivate: [TokenGuard], data: {animationState: 'EntryForm'}},
  { path: 'wheel', component: WheelComponent, data: {animationState: 'Wheel'} },
  // { path: 'closed', component: ErrorsComponent},
  { path: '**', redirectTo: 'wheel' }
];


/*
const routes: Routes = [
  { path: 'closed', component: ErrorsComponent},
  { path: '**', redirectTo: 'closed' }
];
*/


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
