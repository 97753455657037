<div class="page-wrapper">
  <section class="form-header">
    <img src="assets/images/closed_header_desktop.png"
    alt="Voge Header Images"
    sizes="(max-width:600px) 480px, (max-width: 800px) 800px, 1024px"
    srcset="assets/images/closed_header_phone.png 480w,
            assets/images/closed_header_tablet.png 800w,
            assets/images/closed_header_desktop.png 1024w
    "
    class="header-image"
    >
  </section>

  <div class="form-wrapper">
    <img src="assets/images/wheel_closed.png" alt="">
  </div>
</div>

