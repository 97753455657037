import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  saveEligibilityToken(): void {

    // Save a token to local storage
    let currentDate = new Date().valueOf();
    let expDate = currentDate + (environment.tokenExpiry*60*1000);

    localStorage.setItem('vogue-user-state-new', expDate.toString() );
  }

  saveSpinToken(token: string): void {
    localStorage.setItem('vogue-spin-token', token);
  }

  getSpinToken(): string {
    return localStorage.getItem('vogue-spin-token');
  }

  checkUser(): boolean {

    // Grab the token from storage
    let token = localStorage.getItem('vogue-user-state-new');

    // If there's no token, we're good to proceed
    if(!token) {
      return true;
    }

    // If the token exists, check the date it was stored
    let currentDate = new Date().valueOf();
    let tokenDate = Number.parseInt(token);

    if(currentDate > tokenDate) { return true;}

    // User not eligible
    return false;
  }
}
