import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PrizeInfo } from '../models/PrizeInfo';
import { PRIZE_TYPE } from '../models/PrizeType';
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss'],
})
export class WheelComponent implements OnInit {
  PRIZE_TYPE = PRIZE_TYPE;
  vogueWebsiteLink = environment.vogueWebsiteLink;

  showPopup: boolean = false;
  isWinner: boolean = false;
  prizeType: string = '';
  prizeCode: string = '';

  private changeDector: ChangeDetectorRef;

  constructor(
    private localStorageService: LocalStorageService,
    private ref: ChangeDetectorRef
  ) {
    // Save token for user to storage
    if (environment.enableTimeToken) {
      this.localStorageService.saveEligibilityToken();
    }

    this.changeDector = ref;
  }

  ngOnInit(): void {}

  public setPrize(prizeInfo: PrizeInfo): void {
    this.isWinner = prizeInfo.isWinner;
    this.prizeType = prizeInfo.prizeType;
    this.prizeCode = prizeInfo.prizeCode;
  }

  public loadingFinished(): void {
    this.showPopup = true;
    this.changeDector.detectChanges();
  }

  public wheelSpun(): void {
    this.showPopup = false;
  }

  public wheelFinished(prize: string): void {
    if (prize === 'Win') {
      this.isWinner = true;
      this.changeDector.detectChanges();
    } else {
      console.log('Do Nothing');
    }
  }

  public gotoUnbounce(): void {
    window.open('https://download.viewa.com/retail-fest/', '_blank');
  }
}
