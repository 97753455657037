import { animate, animateChild, group, keyframes, query, style, transition, trigger } from '@angular/animations';

export const routeTransitionAnimations = trigger('triggerName', [
  transition('EntryForm => Wheel', [
    /*
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('1s ease-out', style({ left: '100%', opacity: 0 }))]),
      query(':enter', [animate('1s ease-out', style({ left: '0%', opacity: 1 }))])
     ]),
     query(':enter', animateChild())
     */

      animate('0.5s ease-out', keyframes([
          style({
              offset: .2,
              opacity: 1,
              transform: 'translateX(20px)'
          }),
          style({
              offset: 1,
              opacity: 0,
              transform: 'translateX(-100%)'
          })
      ]))

  ])
 ]);
