import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { EntryForm } from '../models/EntryForm';
import { EntryResponse } from '../models/EntryResponse';

@Injectable({
  providedIn: 'root'
})
export class EntryFormService {

  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  submitEntryForm(entryForm: EntryForm): Observable<EntryResponse> {
    return this.http.post<EntryResponse>(`${this.baseUrl}/EntryForm`, entryForm);
  }
}
