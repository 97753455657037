import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { SpinResponse } from '../models/SpinResponse';

@Injectable({
  providedIn: 'root'
})
export class WheelService {

  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  spinWheel(token: string): Observable<SpinResponse> {
    return this.http.get<SpinResponse>(`${this.baseUrl}/spin/${token}`);
  }

  isWheelActive(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/spin/active`);
  }
}
