import { FormArray, AbstractControl } from '@angular/forms';

export function ValidateSpend (control: AbstractControl):{[key: string]: boolean} | null {

    let fa = control as FormArray;
    let totalSpend = 0;

    for (let formControl of fa.controls) {
      const spendAmount = formControl.get('totalSpend');
      const spendString = spendAmount.value as string;
      const spendFormatted = spendString.replace('$', '');

      if(!isNaN(Number(spendFormatted)))
      {
        totalSpend += Number(spendFormatted)
      }
    }
    if(totalSpend >= 150.0) {
      return null;
    }

    return {'spendValidator': true}
}
