import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  baseUrl = environment.trackingBaseUrl;

  constructor(private http: HttpClient) { }

  trackPageload(campaignId: string) {
    return this.http.get(`${this.baseUrl}/sites?cid=${campaignId}&evt=pageload`);
  }

}
