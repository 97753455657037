import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

    private baseUrl = environment.apiBaseUrl;

    constructor(private http: HttpClient) { }

    uploadFile(file: FormData) {
      return this.http.post(`${this.baseUrl}/upload`, file, { reportProgress: true, observe: 'events'})
    }

}
